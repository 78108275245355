export default function FeedbackButton() {
  return (
    <a
      href={`mailto:info@orderme.nl?subject=Feedback on page ${window.location.pathname}`}
      target="_blank"
      rel="noopener noreferrer"
      className="px-3 py-1 rounded-md border font-bold text-sm hover:bg-white"
    >
      Feedback on this page?
    </a>
  );
}
