import { getSupabaseResource, supabase } from "utils/supabase";
import { getUser } from "utils/authServer";

import { useMemo } from "react";
import useResource from "hooks/useResource";

import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import Plus from "components/icons/Plus";
import FeedbackButton from "components/FeedbackButton";

export default function Admin({ auth }) {
  const userWithRestaurantsResource = useMemo(
    () =>
      getSupabaseResource(
        supabase
          .from("users")
          .select(
            `
              *,
              user_restaurants (
                *,
                restaurants (*)
              )
            `
          )
          .eq("id", auth.user.id),
        { single: true, refreshIfExists: true }
      ),
    [auth.user.id]
  );
  const userWithRestaurants = useResource(userWithRestaurantsResource);
  const restaurants = userWithRestaurants.user_restaurants.flatMap(
    ({ restaurants }) => restaurants
  );

  return (
    <>
      <Head>
        <title>Admin | OrderMe</title>
      </Head>
      <div className="absolute top-0 right-0 p-2">
        <Link href="/auth/logout">
          <a className="text-primary-600">Log out</a>
        </Link>
      </div>
      <div className="min-h-screen p-4 sm:p-8 space-y-4 sm:space-y-8 bg-gray-100">
        <h1>
          <img className="h-8 mx-auto" src="/logo-orderme.svg" alt="OrderMe" />
        </h1>
        <div className="max-w-2xl mx-auto space-y-4">
          {restaurants.map((restaurant) => (
            <div
              key={restaurant.id}
              className="sm:flex rounded-md shadow-sm bg-white overflow-hidden"
            >
              <div className="relative sm:w-32 h-32 sm:h-auto bg-gray-200">
                {restaurant.cover && (
                  <Image
                    src={restaurant.cover}
                    layout="fill"
                    className="object-cover rounded-t-md sm:rounded-r-none sm:rounded-l-md"
                  />
                )}
              </div>
              <div className="flex-grow p-4 space-y-4">
                <div>
                  <h3 className="text-xl font-bold">{restaurant.name}</h3>
                  <p>{restaurant.address}</p>
                </div>
                <div className="space-y-2 sm:flex sm:justify-end sm:space-x-4 sm:space-y-0">
                  <Link href={`/${restaurant.slug}`}>
                    <a className="block rounded-md px-4 py-2 leading-4 border text-primary-600 hover:text-primary-700 text-center">
                      View as guest
                    </a>
                  </Link>
                  <Link href={`/${restaurant.slug}/admin`}>
                    <a className="block rounded-md px-4 py-2 leading-4 bg-primary-600 hover:bg-primary-700 text-white text-center font-bold">
                      Manage restaurant
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          {restaurants.length === 0 && (
            <div className="p-8 rounded-md bg-primary-100 space-y-2">
              <p className="text-xl font-bold">
                You haven&apos;t created any shops yet. Get started by clicking
                the button below!
              </p>
            </div>
          )}
          <Link href="/admin/new-shop">
            <a className="flex w-full justify-center items-center space-x-4 py-2 bg-primary-600 hover:bg-primary-700 text-white font-bold rounded-md">
              <Plus className="h-4" />
              <span>New shop</span>
            </a>
          </Link>
          <div className="flex justify-center">
            <FeedbackButton />
          </div>
        </div>
      </div>
    </>
  );
}
Admin.authPolicy = {
  isAuthorized: (auth) => auth.user,
  redirect: "/auth/login",
};

export async function getServerSideProps({ req }) {
  const { user, error } = await getUser(req);

  if (error) {
    return { props: {} };
  }

  const { data: invites, error: selectError } = await supabase
    .from("restaurant_invites")
    .select("*")
    .eq("email", user.email);

  if (selectError) {
    return { props: {} };
  }

  if (invites) {
    await supabase.from("user_restaurants").insert(
      invites.map((invite) => ({
        user_id: user.id,
        restaurant_id: invite.restaurant_id,
        role: invite.role,
      }))
    );
    await supabase
      .from("restaurant_invites")
      .delete()
      .in(
        "id",
        invites.map((invite) => invite.id)
      );
  }

  return { props: {} };
}
